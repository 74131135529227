import React from 'react';
import DataFilters from "../../services/data-filters";

export default function BundleDvrDetails({ bundle }) {
    return (
        <div className="dvr-properties">
            <div className="borderless row">
                {!bundle.cloudDvr && (
                    <div>
                        <h4>No DVR Options</h4>
                    </div>
                )}
            </div>
            <div className="borderless">
                {bundle.cloudDvr && (
                    <div>
                        <h4>DVR Options</h4>
                        <div className="row borderless">
                            <span className="col">
                                <label>Cloud DVR: </label>
                                <span> { DataFilters.yesNo(bundle.cloudDvr.enabled) }</span>
                                {bundle.cloudDvr.cost && (
                                    <span className="font-bold">({ DataFilters.currency(bundle.cloudDvr.cost)} / month extra)</span>
                                )}
                            </span>
                        </div>
                        {bundle.cloudDvr.commercialSkipCost && (
                            <div className="row borderless">
                                <span className="col"><b>Cloud DVR Warning: { DataFilters.currency(bundle.cloudDvr.commercialSkipCost) }/month extra for commercial skips!</b></span>
                            </div>
                        )}
                        <div className="row borderless">
                            <span className="col"><label>DVR Age Limit: </label><span> {  DataFilters.noneIfBlank(bundle.cloudDvr.ageLimit, 'Days') }</span></span>
                        </div>
                        <div className="row borderless">
                            <span className="col"><label>DVR Storage Limit: </label><span> {  DataFilters.noneIfBlank(bundle.cloudDvr.storageLimit, 'Hours') }</span></span>

                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
