import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';

export default function NotFound() {
    return (
        <Container>
            <hr />
            <Row>
                <Col>
                    <div className="h3 text-center">Oops! Not Found</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>We're sorry, but the page you're looking for cannot be found.
                    </p>
                    <Link to="/help">Help</Link>
                </Col>
            </Row>
        </Container>
    );
}
