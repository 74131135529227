import React from 'react';
import {Link} from 'react-router-dom';
import ProviderListSearchInput from './provider-list-search-input';
import {getChannels, isChannelLoading} from "../../redux/selectors";
import {connect} from "react-redux";
import {fetchChannels} from "../../redux/actions/channel.action";
import Loading from '../shared/loading';

function ProviderListSearch({ updateSelectedChannels, channels, isChannelLoading, fetchChannels }) {
    if (!channels) {
        // TODO: this check to see if props.channels has already been loaded (and if not, to load it)
        //       will have to be in every component that depends on channel store
        if (!isChannelLoading) {
            fetchChannels();
        }
        return <Loading/>;
    }

    return (
        <div className="jumbotron">
            <h1>Find Live TV Streaming Providers</h1>
            <ProviderListSearchInput
                channels={channels}
                onChange={changes => updateSelectedChannels(changes, channels)}
                searchIcon="fas fa-search fa-2x"
                badgeColor="primary"
                minimumInputLength="0"
            />
            <Link to={{
                pathname: '/help',
                search: 'feedbackType=search-options'
            }}>Looking for more options?</Link>
        </div>
    );
}

// add imported selectors to props
const mapStateToProps = state => {
    return {
        channels: getChannels(state),
        isChannelLoading: isChannelLoading(state)
    };
};
// add imported user actions to props so they can be passed to auth function
const mapDispatchToProps = { fetchChannels };

// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(ProviderListSearch);
