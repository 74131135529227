import React from 'react';

export default function FormError ({ formErrorObject, field }) {
    if (!formErrorObject || !formErrorObject[field]) {
        return '';
    }

    return (
        <label
            className="alert alert-danger">
            { formErrorObject[field] }
        </label>
    )
}
