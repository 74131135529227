import RequestService from '../../services/request';
import config from '../../services/config';

export const FETCH_BUNDLES_BEGIN   = 'FETCH_BUNDLES_BEGIN';
export const FETCH_BUNDLES_SUCCESS = 'FETCH_BUNDLES_SUCCESS';
export const FETCH_BUNDLES_FAILURE = 'FETCH_BUNDLES_FAILURE';

const url = `${config.apiUrl}/providers`;

// exported async action, composed of multiple sync actions at each stage
export function fetchBundles() {
    return dispatch => {
        dispatch(fetchBundlesBegin());
        return RequestService.get(url)
            .then(json => {
                const bundles = convertProvidersToBundles(json.data);
                dispatch(fetchBundlesSuccess(bundles));
                return bundles;
            })
            .catch(error => dispatch(fetchBundlesFailure(error)));
    };
}

// exported actions
export const fetchBundlesBegin = () => ({
    type: FETCH_BUNDLES_BEGIN
});

export const fetchBundlesSuccess = bundles => ({
    type: FETCH_BUNDLES_SUCCESS,
    payload: { bundles }
});

export const fetchBundlesFailure = error => ({
    type: FETCH_BUNDLES_FAILURE,
    payload: { error }
});

// internal functions

// convert incoming API data (array of providers, each provider containing multiple bundles)
// to a map of bundles, each bundle containing its parent provider (key: parentProvider)
// the map of bundles returned is an Object with each key set to the bundle ID, and the key's value set to the bundle data
function convertProvidersToBundles(providers) {
    const bundlesIndexedById = {};
    providers.forEach(provider => {
        provider.bundles.forEach(bundle => {
            const updatedBundle = {...bundle};
            updatedBundle.parentProvider = {...provider};
            delete updatedBundle.parentProvider.bundles;
            // key: bundle ID.  value: bundle data, with parentProvider
            bundlesIndexedById[bundle.id] = updatedBundle;
        })
    });
    return bundlesIndexedById;
}
