import React from 'react';
import ChannelView from '../channels/channel-view';

export default function ChannelList({ channels, channelMatches }) {
    // TODO highlight which channels are included in channelMatches

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-auto mr-auto">
                    <h4>Channels</h4>
                </div>
                <div className="col-auto">
                    <label>Channels Found: {channelMatches.length}</label>
                </div>
            </div>
            <div className="row">
                <div className="col mb-2">
                    {channels.map((channel, index) => {
                        return <ChannelView key={channel} channelId={channel} channelIndex={index} channelCount={channels.length} />
                    })}
                </div>
            </div>
            {channels.length === 0 && (
                <div className="row">
                    <div className="col">
                        <p>None</p>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
