import React from 'react';
import RatingWrapper from "../reviews/rating-wrapper";
import ReviewService from "../../services/review";
import Loading from '../shared/loading';

export default function BundleRatingSummary({ bundle, reviews }) {
    if (!reviews) return <Loading />;

    const reviewCount = reviews.length;
    const ratingScore = ReviewService.getRatingAggregateScore(reviews);

    return (
        <h5>
            <span title={`Rating score: ${ratingScore}`}>
            <RatingWrapper readonly={true} ratingScore={ratingScore} />
            </span>
            <span className="review-count"
                title={`Number of reviews: ${reviewCount}`}>&nbsp;({reviewCount})</span>
        </h5>
    )
}
