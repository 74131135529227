import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Container } from 'reactstrap';

export default function Terms() {
    return (
        <Container>
            <hr />
            <Row className="d-md-none">
                <Col>
                    <Button color="primary" tag={Link} to="/providers"><span className="fas fa-angle-left"></span> Back to Provider Guide</Button>
                </Col>
                <Col>
                    <Button color="primary" tag={Link} to="/help" className="float-right"><span className="fas fa-question"></span> Help</Button>
                </Col>
            </Row>
            <br className="d-md-none" />
            <Row>
                <Col>
                    <h3 className="text-center">Terms and Conditions</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        By visiting Stream My Shows ("the Site"), you are accepting and consenting to the Terms of Conditions below.
                        All services are provided by Yukon Solutions Inc.
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <h4>Providers</h4>
                    <ul>
                        <li>This product is not affiliated with any provider.</li>
                        <li>Only those providing streaming services in the United States are currently listed.</li>
                        <li>We try to keep all information as accurate as possible.</li>
                    </ul>
                    <p>
                        To suggest updates, please <Link to={{
                            pathname: '/help',
                            search: 'feedbackType=report-data'
                        }}>Contact Us.</Link>
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <h3 className="text-center">Privacy Policy</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>What information we collect about you</h4>
                    <div>
                        In order to provide a better experience for everyone, we use the Google Analytics service to track some data <span className="font-italic">automatically:</span>
                    </div>
                    <div><ul>
                        <li>This service includes the use of cookies.</li>
                        <li>We do not get any personal information from this service.</li>
                        <li>For information on what Google does with this data: <a href="https://policies.google.com/privacy/partners">How Google uses data when you use our partners' sites or apps</a></li>
                    </ul>
                    </div>
                    <div>
                        We also collect data <span className="font-italic">you provide</span> in our Feedback form:
                    </div>
                    <div><ul>
                        <li>We use your Name and Email in order to respond to you.</li>
                    </ul>
                    </div>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>What information about you we share</h4>
                    <br />
                        <p>With the exception of the Google Analytics service mentioned above, <b>we do not share your data with third-parties.</b></p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <span className="float-right font-italic">Last updated May 1, 2018</span>
                </Col>
            </Row>
        </Container>

);
}
