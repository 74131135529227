import React from 'react';
import { Link } from 'react-router-dom';
import SearchResultService from '../../services/search-result';
import DataFilters from '../../services/data-filters';

function getAddOnNameText(searchResult) {
    return searchResult.searchScore.bestAddOns.length ? SearchResultService.getAddOnNameText(searchResult) : '';

}

function getAddOnPrice(searchResult, bundle) {
    return DataFilters.currency(searchResult.searchScore.bestAddOns.length ? SearchResultService.getAddOnPrice(searchResult, bundle.monthlyPrice) : bundle.monthlyPrice);
}

function getAddOnPriceLabel(searchResult) {
    return searchResult.searchScore.bestAddOns.length ? SearchResultService.getAddOnPriceLabel(searchResult) : '';
}

function getChannelMatchText(searchResult) {
    return SearchResultService.getChannelMatchText(searchResult);
}

export default function ProviderListSearchResult({ searchResult, bundles, selectedChannelIds }) {
    const bundle = SearchResultService.getBundleFromSearchResult(bundles, searchResult);
    const channelQueryParamKey = selectedChannelIds.join(',');

    // TODO change table to container grid.  still need table borders and header formatting
    return (
        <tr className="row">
            <td className="col col-sm-6">{ bundle.name }{ getAddOnNameText(searchResult) }</td>
            <td className="col col-sm-4">{ getAddOnPrice(searchResult, bundle) } { getAddOnPriceLabel(searchResult) }</td>
            <td className="col">{ getChannelMatchText(searchResult) }</td>
            <td className="col col-sm-4 text-right">
                <Link to={{
                    pathname: `/providers/${bundle.parentProvider.id}/bundles/${bundle.id}`,
                    search: `?channels=${channelQueryParamKey}`
                }}>
                    <i className="fas fa-info-circle"></i>
                    <span className=""> Details</span>
                </Link>
            </td>
        </tr>
    );
}
