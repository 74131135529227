import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import urlLib from 'url';

import Recaptcha from '../shared/recaptcha';
import Loading from '../shared/loading';
import FormError from '../shared/form-error';
import FeedbackService from '../../services/feedback';
import ErrorsService from '../../services/errors';

function getPlaceholder(feedback) {
    if (feedback.feedbackType === 'question') {
        return '(required for questions)';
    } else if (!feedback.anonymousFeedback) {
        return '(toggle "Submit anonymously" to skip)';
    }
    return '';
}

function getSubmittedMessage(feedbackType) {
    switch (feedbackType) {
        case 'question':
            return 'Thank you for contacting us about your question!  We will respond promptly.';
        case 'problem':
            return 'Thank you for letting us know about the problem!  We will respond promptly.';
        case 'search-options':
        case 'report-data':
        case 'generic':
            return 'Thank you! Your feedback will help us build a better user experience.';
        default:
            return 'Thank you for your submission!';
    }
}

function updateFeedbackType(newFeedbackType, feedback, setFeedback) {
    let latestFeedback = feedback;
    if (feedback.feedbackType === 'report-data') {
        latestFeedback = handleChange('reportDataArea', 'providers', latestFeedback, setFeedback);
    }
    if (feedback.feedbackType !== 'search-options') {
        latestFeedback = handleChange('searchOptionShows', false, latestFeedback, setFeedback);
    }
    if (feedback.feedbackType === 'question' && feedback.anonymousFeedback === true) {
        latestFeedback = handleChange('anonymousFeedback', false, latestFeedback, setFeedback);
    }
    handleChange('feedbackType', newFeedbackType, latestFeedback, setFeedback);
}

function handleChange(fieldName, newValue, feedback, setFeedback) {
    const newFeedback = {
        ...feedback,
        [fieldName]: newValue
    };
    setFeedback(newFeedback);
    return newFeedback;
}

async function submitFeedback(event, feedback, setters, recaptchaRef) {
    event.preventDefault();

    // reset variables that represent the state of the form (error / success)
    setters.setFormErrorText('');
    setters.setFormErrorObject({});
    setters.setFeedbackTypeSubmitted('');
    setters.setLoading(true);

    try {
        await FeedbackService.createFeedback(feedback);
        setters.setFeedbackTypeSubmitted(getSubmittedMessage(feedback.feedbackType));
    } catch (err) {
        setters.setFormErrorText(ErrorsService.extractErrorMessage(err, 'submitting feedback'));
        setters.setFormErrorObject(ErrorsService.extractFormErrors(err));
        recaptchaRef.current.reset();
        setters.setCaptcha(null);
        console.error(err);
    } finally {
        setters.setLoading(false);
    }
}


function toggleAnonymous(checked, feedback, setFeedback) {
    let latestFeedback = feedback;
    if (checked === true) {
        latestFeedback = handleChange('submitterName', '', latestFeedback, setFeedback);
        latestFeedback = handleChange('submitterEmail', '', latestFeedback, setFeedback);
    }
    handleChange('anonymousFeedback', checked, latestFeedback, setFeedback);
}

function toggleSearchOptionShows(checked, feedback, setFeedback) {
    if (checked === true) {
        handleChange('searchOptionsShowSeasons', 'na', feedback, setFeedback);
    } else {
        handleChange('searchOptionsShowSeasons', '', feedback, setFeedback);
    }
}

export default function Help(props) {
    // use incoming search param(s), if any, to set default feedback
    const queryParams = urlLib.parse(props.location.search, {parseQueryString: true}).query;
    const feedbackType = queryParams && queryParams.feedbackType ? queryParams.feedbackType : 'question';
    const defaultFeedback = {
        feedbackType,
        submitterName: '',
        submitterEmail: ''
    };

    const [feedback, setFeedback] = useState(defaultFeedback);
    const [formErrorText, setFormErrorText] = useState('');
    const [formErrorObject, setFormErrorObject] = useState({});
    const [feedbackTypeSubmitted, setFeedbackTypeSubmitted] = useState('');
    const [captcha, setCaptcha] = useState(null);
    // TODO Make loading icon a better overlay
    const [loading, setLoading] = useState(false);
    const feedbackFormRef = useRef(null);
    const recaptchaRef = useRef(null);

    const allFeedbackData = {
        ...feedback,
        captcha
    };

    const setters = {
        setFeedback,
        setFormErrorText,
        setFormErrorObject,
        setFeedbackTypeSubmitted,
        setLoading,
        setCaptcha
    };

    const submitButtonDisabled = !feedbackFormRef || !captcha || !feedbackFormRef.current || !feedbackFormRef.current.checkValidity();

    return (
        <div className="container">

            <hr />

            <div className="row d-md-none">
                <div className="col">
                    <Link to="/providers" className="btn btn-primary"><span className="fas fa-angle-left"></span> Back to Provider Guide</Link>
                </div>
            </div>
            <br className="d-md-none" />
            <div className="row">
                <div className="col">
                    <div className="h3 text-center">Help</div>
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col">
                    <div className="h5">Please read the <Link to="/terms">Terms and Conditions.</Link>
                    </div>
                    <div className="h6">&nbsp;</div>
                </div>
            </div>

            <div className="row" id="contactus">
                {feedbackTypeSubmitted && (
                    <div className="col">
                        <span className="h4">Contact Us</span>
                        <div className="alert alert-success mt-3" role="alert">
                            <span>{ feedbackTypeSubmitted } </span>
                        </div>
                    </div>
                )}
                {!feedbackTypeSubmitted && (
                    <div className="col">
                        {loading && <Loading/>}
                        <span className="h4">Contact Us</span>
                        <form ref={feedbackFormRef}
                              onSubmit={event => submitFeedback(event, allFeedbackData, setters, recaptchaRef)}>
                            <div className="form-group">
                                <br />
                                <select name="feedbackType"
                                        className="form-control"
                                        onChange={(event) => updateFeedbackType(event.target.value, feedback, setFeedback)}
                                        required
                                        value={feedback.feedbackType}>
                                    <option value="question">I have a question</option>
                                    {/* <option value="contact">Contact Us</option> */}
                                    <option value="problem">There are problems with the site</option>
                                    <option value="search-options">I need more search options</option>
                                    <option value="report-data">There is incorrect / missing info</option>
                                    <option value="generic">Generic Feedback</option>
                                </select>
                                <FormError field="feedbackType" formErrorObject={formErrorObject} />
                            </div>
                            {feedback.feedbackType === 'report-data' && (
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio"
                                               name="reportDataArea"
                                               value="providers"
                                               className="form-check-input"
                                               onChange={event => event.target.value ? handleChange('reportDataArea', 'providers', feedback, setFeedback) : null}
                                               />
                                        <label className="form-check-label">Providers</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio"
                                               name="reportDataArea"
                                               value="channels"
                                               className="form-check-input"
                                               onChange={event => event.target.value ? handleChange('reportDataArea', 'channels', feedback, setFeedback) : null}
                                        />
                                        <label className="form-check-label">Channels</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio"
                                               name="reportDataArea"
                                               value="other"
                                               className="form-check-input"
                                               onChange={event => event.target.value ? handleChange('reportDataArea', 'other', feedback, setFeedback) : null}
                                        />
                                        <label className="form-check-label">Other / Not sure</label>
                                        <FormError field="reportDataArea" formErrorObject={formErrorObject} />
                                    </div>
                                    {feedback.reportDataArea === 'other' && (
                                        <div className="form-group">
                                            <label>Other Area: </label>
                                            <input
                                                type="text"
                                                name="reportDataAreaOther"
                                                className="form-control"
                                                placeholder="(optional)"
                                                onChange={event => handleChange('reportDataAreaOther', event.target.value, feedback, setFeedback)}
                                                value={feedback.reportDataAreaOther}
                                            />
                                            <FormError field="reportDataAreaOther" formErrorObject={formErrorObject} />
                                        </div>
                                    )}
                                </div>
                            )}
                            {feedback.feedbackType === 'search-options' && (
                                <div>
                                    <label>Search by: </label>
                                    <div className="form-check">
                                        <input type="checkbox"
                                               name="searchOptionShows"
                                               value="true"
                                               className="form-check-input"
                                               onChange={event => toggleSearchOptionShows(event.target.checked, feedback, setFeedback)}
                                        />
                                        <label className="form-check-label">shows</label>
                                        <FormError field="searchOptionShows" formErrorObject={formErrorObject} />

                                        {feedback.searchOptionShows && (
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio"
                                                           name="searchOptionsShowSeasons"
                                                           value="latest"
                                                           onChange={event => event.target.value ? handleChange('searchOptionsShowSeasons', 'latest', feedback, setFeedback) : null}
                                                           className="form-check-input" />
                                                    <label className="form-check-label">Latest season only</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio"
                                                           name="searchOptionsShowSeasons"
                                                           value="previous"
                                                           onChange={event => event.target.value ? handleChange('searchOptionsShowSeasons', 'latest', feedback, setFeedback) : null}
                                                           className="form-check-input" />
                                                    <label className="form-check-label">Previous seasons only (to save $)</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio"
                                                           name="searchOptionsShowSeasons"
                                                           value="all"
                                                           onChange={event => event.target.value ? handleChange('searchOptionsShowSeasons', 'all', feedback, setFeedback) : null}
                                                           className="form-check-input" />
                                                    <label className="form-check-label">All seasons</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio"
                                                           name="searchOptionsShowSeasons"
                                                           value="na"
                                                           onChange={event => event.target.value ? handleChange('searchOptionsShowSeasons', 'na', feedback, setFeedback) : null}
                                                           className="form-check-input" />
                                                    <label className="form-check-label">Don't care</label>
                                                    <FormError field="searchOptionsShowSeasons" formErrorObject={formErrorObject} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox"
                                               name="searchOptionMovies"
                                               value="true"
                                               onChange={event => handleChange('searchOptionMovies', event.target.checked, feedback, setFeedback)}
                                               className="form-check-input" />
                                        <label className="form-check-label">movies</label>
                                        <FormError field="searchOptionMovies" formErrorObject={formErrorObject} />
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox"
                                               name="searchOptionSports"
                                               value="true"
                                               onChange={event => handleChange('searchOptionSports', event.target.checked, feedback, setFeedback)}
                                               className="form-check-input" />
                                        <label className="form-check-label">sports leagues</label>
                                        <FormError field="searchOptionSports" formErrorObject={formErrorObject} />
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox"
                                               name="searchOptionDevices"
                                               value="true"
                                               onChange={event => handleChange('searchOptionDevices', event.target.checked, feedback, setFeedback)}
                                               className="form-check-input" />
                                        <label className="form-check-label">supported devices</label>
                                        <FormError field="searchOptionDevices" formErrorObject={formErrorObject} />
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox"
                                               name="searchOptionNotLive"
                                               value="true"
                                               onChange={event => handleChange('searchOptionNotLive', event.target.checked, feedback, setFeedback)}
                                               className="form-check-input" />
                                        <label className="form-check-label">include streaming providers that are not live (e.g. Netflix, Amazon Prime, Hulu Plus, Crackle)</label>
                                        <FormError field="searchOptionNotLive" formErrorObject={formErrorObject} />
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox"
                                               name="searchOptionOther"
                                               value="true"
                                               onChange={event => handleChange('searchOptionOther', event.target.checked, feedback, setFeedback)}
                                               className="form-check-input" />
                                        <label className="form-check-label">other</label>
                                        <FormError field="searchOptionOther" formErrorObject={formErrorObject} />
                                        {feedback.searchOptionOther && (
                                            <div className="form-group">
                                                <label>Other Search Option(s): </label>
                                                <input
                                                    type="text"
                                                    name="searchOptionOtherText"
                                                    className="form-control"
                                                    onChange={event => handleChange('searchOptionOtherText', event.target.value, feedback, setFeedback)}
                                                    placeholder="(optional)" />
                                                <FormError field="searchOptionOtherText" formErrorObject={formErrorObject} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="form-group">
                                {feedback.feedbackType !== 'question' && <label>Comments: </label>}
                                <textarea
                                    name="comments"
                                    className="form-control"
                                    required
                                    value={feedback.comments}
                                    onChange={event => handleChange('comments', event.target.value, feedback, setFeedback)}
                                    placeholder="(required)"
                                    rows="5">
                                </textarea>
                                <FormError field="comments" formErrorObject={formErrorObject} />
                            </div>

                            {feedback.feedbackType !== 'question' && (
                                <div className="form-check">
                                    <input type="checkbox"
                                           name="anonymousFeedback"
                                           value="true"
                                           onChange={event => toggleAnonymous(event.target.checked, feedback, setFeedback)}
                                           className="form-check-input"
                                    />
                                    <label className="form-check-label">Submit anonymously</label>
                                    <FormError field="anonymousFeedback" formErrorObject={formErrorObject} />
                                </div>
                            )}

                            <div className="form-group">
                                <label>Name: </label>
                                <input
                                    type="text"
                                    name="submitterName"
                                    className="form-control"
                                    readOnly={feedback.anonymousFeedback}
                                    required={!feedback.anonymousFeedback}
                                    value={feedback.submitterName}
                                    placeholder={getPlaceholder(feedback)}
                                    onChange={event => handleChange('submitterName', event.target.value, feedback, setFeedback)}
                                />
                                <FormError field="submitterName" formErrorObject={formErrorObject} />
                            </div>

                            <div className="form-group">
                                <label>Email: </label>
                                <input
                                    type="email"
                                    name="submitterEmail"
                                    className="form-control"
                                    value={feedback.submitterEmail}
                                    readOnly={feedback.anonymousFeedback}
                                    required={!feedback.anonymousFeedback}
                                    placeholder={getPlaceholder(feedback)}
                                    onChange={event => handleChange('submitterEmail', event.target.value, feedback, setFeedback)}
                                />
                                <FormError field="submitterEmail" formErrorObject={formErrorObject} />
                            </div>

                            {formErrorText && (
                                <div className="alert alert-danger" role="alert">
                                    <span>{ formErrorText }</span>
                                </div>
                            )}
                            <div className="form-group">
                                <Recaptcha
                                    onChange={value => setCaptcha(value)}
                                    recaptchaRef={recaptchaRef}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={submitButtonDisabled}>Submit</button>
                        </form>
                    </div>
                )}
            </div>

            <br />

            <div className="row">
                <div className="col">
                    <span className="h4">About Us</span>
                    <br /><br />
                    <p>
                        Stream My Shows is dedicated to finding the best streaming content provider(s) for each person's unique
                        preferences.
                        <br /><br />
                        In order to help the site pay for itself, the links to some providers are affiliate links, that is, we get paid
                        when you click on them. We do our best to work with all providers, and we do not modify results based on which
                        providers work with us.
                        <br /><br />
                    </p>
                </div>
            </div>
        </div>

    );
}
