import React, { useState, useRef } from 'react';
import RatingWrapper from "./rating-wrapper";
import ReviewService from "../../services/review";
import ErrorsService from "../../services/errors";
import RecaptchaComponent from "../shared/recaptcha";
import FormError from '../shared/form-error';
import Loading from '../shared/loading';

function setReviewField(fieldName, fieldValue, reviewData, setReviewData) {
    const newReviewData = {...reviewData};
    newReviewData[fieldName] = fieldValue;
    setReviewData(newReviewData);
}

async function saveNewReview(event, reviewType, reviewTargetId, reviewTargetParentId, reviewData, setters, recaptchaRef) {
    event.preventDefault();

    // reset variables that represent the state of the form (error / success)
    setters.setFormErrorText('');
    setters.setFormErrorObject({});
    setters.setLoading(true);

    try {
        const savedReview = await ReviewService.createReview(reviewType, reviewTargetId, reviewTargetParentId, reviewData);
        setters.onSave(savedReview);
    } catch (err) {
        setters.setFormErrorText(ErrorsService.extractErrorMessage(err, 'submitting a review'));
        setters.setFormErrorObject(ErrorsService.extractFormErrors(err));
        if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
        setters.setCaptcha(null);
        console.error(err);
        setters.setLoading(false);
    }
}

export default function NewReviewForm({ reviewType, reviewTargetId, reviewTargetParentId, onCancel, onSave }) {
    const [loading, setLoading] = useState(false);
    const [reviewData, setReviewData] = useState({ reviewTitle: '', reviewText: '', reviewerName: '' });
    const [formErrorText, setFormErrorText] = useState('');
    const [formErrorObject, setFormErrorObject] = useState({});
    const [captcha, setCaptcha] = useState(null);
    const [rating, setRating] = useState(null);
    const reviewFormRef = useRef(null);
    const recaptchaRef = useRef(null);

    // TODO replace Name field with registration (registration will be required for reviewing)

    if (loading) return <Loading />;

    const setters = {
        setLoading,
        setReviewData,
        setCaptcha,
        setFormErrorText,
        setFormErrorObject,
        onSave
    };

    const submitButtonDisabled = !reviewFormRef || !captcha || !rating || !reviewFormRef.current || !reviewFormRef.current.checkValidity();

    const allReviewData = {
        ...reviewData,
        captcha,
        ratingScore: rating
    };

    return (
        <div className="new-review">
            <hr></hr>
            <h5>Create Review</h5>
            <form className="new-review-form"
                  ref={reviewFormRef}
                  onSubmit={(event) => {saveNewReview(event, reviewType, reviewTargetId, reviewTargetParentId, allReviewData, setters, recaptchaRef)}}>
                <div className="new-review-score form-group">
                    <label>Rating:</label>
                    <RatingWrapper
                        onChange={value => setRating(value)}
                        readonly={false} ratingScore={rating ? rating : 0}/>
                    <FormError field="ratingScore" formErrorObject={formErrorObject} />
                </div>
                <div className="new-review-title form-group">
                    <label htmlFor="newReviewTitle">Summary:</label>
                    <input id="newReviewTitle"
                           className="form-control"
                           name="reviewTitle"
                           type="text"
                           required
                           maxLength="100"
                           value={reviewData.reviewTitle}
                           placeholder="What is the most important feedback for others to know?"
                           onChange={(event) => setReviewField('reviewTitle', event.target.value, reviewData, setReviewData)}
                    />
                    <FormError field="reviewTitle" formErrorObject={formErrorObject} />
                </div>
                <div className="new-review-text form-group">
                    <label htmlFor="newReviewText" className="align-top">Review:</label>
                    <textarea id="newReviewText"
                              className="form-control"
                              name="reviewText"
                              rows="5"
                              required
                              maxLength="2000"
                              value={reviewData.reviewText}
                              placeholder="What did you like or dislike?  How often did you use it?"
                              onChange={(event) => setReviewField('reviewText', event.target.value, reviewData, setReviewData)}>
                    </textarea>
                    <FormError field="reviewText" formErrorObject={formErrorObject} />
                </div>
                <div className="new-review-name form-group">
                    <label htmlFor="newReviewReviewerName">Your Name/Alias:</label>
                    <input id="newReviewReviewerName"
                           className=" form-control"
                           name="reviewerName"
                           required
                           maxLength="50"
                           placeholder="Your name will be displayed next to your review."
                           type=" text"
                           value={reviewData.reviewerName}
                           onChange={(event) => setReviewField('reviewerName', event.target.value, reviewData, setReviewData)}
                    />
                    <FormError field="reviewerName" formErrorObject={formErrorObject} />
                </div>
                {formErrorText && (
                    <div className="alert alert-danger" role="alert">
                        <span>{ formErrorText }</span>
                    </div>
                )}
                <div className="new-review-captcha form-group">
                    <RecaptchaComponent
                        onChange={value => setCaptcha(value)}
                        recaptchaRef={recaptchaRef}
                    />
                    <FormError field="captcha" formErrorObject={formErrorObject} />
                </div>
                <button className="mt-2 mr-2 btn btn-primary btn-save-review text-nowrap" type="submit" disabled={submitButtonDisabled}>Save review</button>
                <button className="mt-2 btn btn-secondary btn-cancel-review" disabled={false} onClick={onCancel}>Cancel</button>
            </form>
        </div>
    );
}
