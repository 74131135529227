import React from 'react';
import ErrorsService from '../../services/errors';

export default function Error ({ error }) {
    ErrorsService.log(error);
    const errorMessage = ErrorsService.extractErrorMessage(error);

    return (
        <div>
            <h2>An error has occurred!</h2>
            <label>Error details: </label>
            <span>{errorMessage}</span>
        </div>
    );
}
