import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function ProviderListSearch(props) {
    return (
        <React.Fragment>
            <Typeahead
                id="provider-list-search"
                labelKey="name"
                multiple={true}
                options={props.channels}
                placeholder="Channel Search"
                onChange={props.onChange}
                className="mt-5 mb-3 provider-list-search"
            />
        </React.Fragment>
    );
}
