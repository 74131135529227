import React, { useEffect } from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// CSS needed for channel search type-ahead widget
import 'react-bootstrap-typeahead/css/Typeahead.css';

// Viewport components
import Main from './components/viewport/main';
import Header from './components/viewport/header';
import Footer from './components/viewport/footer';
import store from "./redux/store";


// ScollToTop for react-router: https://reacttraining.com/react-router/web/guides/scroll-restoration
const ScrollToTop = ({ children, location: { pathname } }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return children;
};

const ScrollToTopWithRouter = withRouter(ScrollToTop);

export default function App() {
    return (
        <Provider store={store}>
            <Router>
                <ScrollToTopWithRouter>
                    <div className="m-3">
                        <Header />
                        <Main />
                        <Footer />
                    </div>
                </ScrollToTopWithRouter>
            </Router>
        </Provider>
    );
}
