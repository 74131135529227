import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

export default function RecaptchaComponent ({ onChange, recaptchaRef }) {
    return (
        <ReCAPTCHA
            sitekey="6LcD4gwTAAAAAHTP0XBH63wlJjx1gucoEYT1Ciag"
            onChange={onChange}
            ref={recaptchaRef}
            required
        />
    )
}
