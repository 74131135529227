import config from '../../services/config';
import RequestService from '../../services/request';

export const FETCH_CHANNELS_BEGIN   = 'FETCH_CHANNELS_BEGIN';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';

const url = `${config.apiUrl}/channels`;

// exported async action, composed of multiple sync actions at each stage
export function fetchChannels() {
    return dispatch => {
        dispatch(fetchChannelsBegin());
        return RequestService.get(url)
            .then(json => {
                dispatch(fetchChannelsSuccess(json.data));
                return json;
            })
            .catch(error => dispatch(fetchChannelsFailure(error)));
    };
}

// exported actions
export const fetchChannelsBegin = () => ({
    type: FETCH_CHANNELS_BEGIN
});

export const fetchChannelsSuccess = channels => ({
    type: FETCH_CHANNELS_SUCCESS,
    payload: { channels }
});

export const fetchChannelsFailure = error => ({
    type: FETCH_CHANNELS_FAILURE,
    payload: { error }
});

// internal functions
