import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import RatingWrapper from './rating-wrapper';
import DataFilters from '../../services/data-filters';

export default function ReviewView({ review }) {
    const reviewDate = DataFilters.date(review.createdAt);

    return (
        <Card>
            <CardBody>
                <CardTitle className="d-flex">
                    <h5>
                        {review.reviewTitle}
                    </h5>
                    <h6 className="ml-auto">
                        <RatingWrapper readonly={true} ratingScore={review.ratingScore} />
                    </h6>
                </CardTitle>
                <CardSubtitle className="d-flex mb-2">
                    <span>
                        {review.reviewerName}
                    </span>
                    <span className="ml-auto">
                        {reviewDate}
                    </span>
                </CardSubtitle>
                <CardText>
                    {review.reviewText}
                </CardText>
            </CardBody>
        </Card>
    );
}
