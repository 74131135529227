import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ProviderList from "../views/provider-list";
import BundleFullDetails from "../views/bundle-full-details";
import Help from "../views/help";
import Terms from "../views/terms";
import NotFound from "../views/not-found";

export default function Main(props) {
    return (
        <Switch>
            <Route exact path="/providers" component={ProviderList}/>
            <Redirect exact from="/" to="/providers"/>
            <Route exact path="/providers/:providerId/bundles/:id"
                   component={BundleFullDetails}/>
            <Route exact path="/help" component={Help}/>
            <Route exact path="/terms" component={Terms} />
            {/*{ path: 'channels', component: ChannelAdminListComponent },*/}
            {/*{ path: 'admin/channels/new', component: ChannelDetailsComponent },*/}
            {/*{ path: 'admin/channels/edit/:id', component: ChannelDetailsComponent },*/}
            {/*{ path: 'admin/providers', component: ProviderAdminListComponent },*/}
            {/*{ path: 'admin/providers/new', component: ProviderAdminDetailComponent },*/}
            {/*{ path: 'admin/providers/edit/:id', component: ProviderAdminDetailComponent },*/}
            {/*{ path: 'login', component: LoginComponent },*/}
            <Route exact path="/404" component={NotFound} />
            <Redirect exact from="**" to="/404"/>
        </Switch>
    );
}
