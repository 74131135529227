import React from 'react';

export default function BundleJumbotron({ bundle }) {
    // TODO add bundleSummaryImage to bundle data.  Use CMS like Cloudinary
    // const defaultImageUrl = 'https://res.cloudinary.com/all-up-in-yo-brown-eye/image/upload/v1564160267/slingtv-black.jpg';
    const style = {
        // backgroundImage: `url(${defaultImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: 'black',
        backgroundPosition: 'left'
    };

    return (
        <div className="jumbotron bundle-jumbotron" style={style} />
    )
}
