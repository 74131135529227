import { combineReducers } from 'redux';
import channel from './channel.reducer';
import bundle from './bundle.reducer';
import searchResult from './search-result.reducer';

export default combineReducers({
    channel,
    bundle,
    searchResult
});
