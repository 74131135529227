import React from 'react';
import {Link} from 'react-router-dom';
import SearchResult from './provider-list-search-result';

export default function ProviderListTable({ searchResults, bundles, selectedChannelIds }) {
    return (
        <React.Fragment>
            <div className="container p-0">
                <div>
                    <label>Provider Packages sorted by:</label>
                    <span> Best value</span>
                    <span className="d-none d-sm-inline"> (most channels found for the price)</span>
                </div>
            </div>
            <div className="container">
                <table className="table table-striped">
                    <thead className="thead-dark">
                    <tr className="row">
                        <th className="col col-sm-6">Provider package</th>
                        <th className="col col-sm-4">Monthly price</th>
                        <th className="col">Channels Found</th>
                        <th className="col col-sm-4 text-right">Learn more</th>
                    </tr>
                    </thead>
                    <tbody>
                    {searchResults.map(searchResult => (
                        <SearchResult key={searchResult.bundleId} searchResult={searchResult} bundles={bundles} selectedChannelIds={selectedChannelIds} />
                    ))}
                    </tbody>
                </table>
                <div className="row">
                    <Link to={{
                        pathname: '/help',
                        search: 'feedbackType=report-data'
                    }}>Report Data Issues</Link>
                </div>
            </div>
        </React.Fragment>
    );
}
