import React from 'react';
import Rating from 'react-rating';

export default function RatingWrapper({ ratingScore, readonly, onChange }) {
    return (
        <Rating
            className="rating-wrapper"
            emptySymbol="fas fa-star rating-star-empty"
            fullSymbol="fas fa-star rating-star-full"
            initialRating={ratingScore}
            readonly={readonly}
            onChange={onChange}
        />
    )
}
