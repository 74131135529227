import React from 'react';
import ProviderListWithData from '../providers/provider-list-with-data';
import Loading from '../shared/loading';
import Error from '../shared/error';
import {getBundles, isBundleLoading, isBundleFetchError, getBundleFetchError } from "../../redux/selectors";
import {connect} from "react-redux";
import {fetchBundles} from "../../redux/actions/bundle.action";

function ProviderList({ bundles, isBundleLoading, fetchBundles, isBundleFetchError, bundleFetchError }) {
    if (!bundles) {
        // TODO: this check to see if props.bundles has already been loaded (and if not, to load it)
        //       will have to be in every component that depends on bundle store
        if (!isBundleLoading && !isBundleFetchError) {
            fetchBundles();
        } else if(isBundleFetchError) {
            return <Error error={bundleFetchError} />
        }
        return <Loading/>;
    }

    return (
        <ProviderListWithData bundles={bundles}/>
    );
}

// add imported selectors to props
const mapStateToProps = state => {
    return {
        bundles: getBundles(state),
        bundleFetchError: getBundleFetchError(state),
        isBundleLoading: isBundleLoading(state),
        isBundleFetchError: isBundleFetchError(state)
    };
};
// add imported user actions to props so they can be passed to auth function
const mapDispatchToProps = { fetchBundles };

// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(ProviderList);
