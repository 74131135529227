import React from 'react';

import BundleProviderLink from '../bundles/bundle-provider-link'
import DataFilters from "../../services/data-filters";
import SearchResultService from "../../services/search-result";
import BundleJumbotron from "./bundle-jumbotron";
import BundleRatingSummary from "./bundle-rating-summary";

function getAddOnNameText(searchResult) {
    return SearchResultService.getAddOnNameText(searchResult);
}

function getAddOnPrice(searchResult, bundle) {
    return SearchResultService.getAddOnPrice(searchResult, bundle.monthlyPrice);
}

function getAddOnPriceLabel(searchResult) {
    return SearchResultService.getAddOnPriceLabel(searchResult);
}


export default function BundleSummary({ bundle, searchResult, reviews }) {
    // current add-on price, price label, and name based on best search result
    const priceText = DataFilters.currency(searchResult.searchScore.bestAddOns.length ? getAddOnPrice(searchResult, bundle) : bundle.monthlyPrice);
    const addOnInLabel = !!searchResult.searchScore.bestAddOns.length;
    const priceLabel = addOnInLabel ? getAddOnPriceLabel(searchResult) : '';
    const addOnName = addOnInLabel ? getAddOnNameText(searchResult) : '';
    const titleMarginBottom = addOnInLabel ? 'mb-0' : '';

    return (
        <div className="bundle-name">
            <BundleJumbotron bundle={bundle}/>
            <div className="row form-group bundle-summary">
                <div className="col-auto mr-auto align-middle">
                    <h2 className={titleMarginBottom}>{bundle.name}</h2>
                    {addOnInLabel && (
                        <span>{addOnName}</span>
                    )}
                    <BundleRatingSummary bundle={bundle} reviews={reviews}/>
                </div>
                <div className="col-auto">
                    <h3 className={titleMarginBottom}>{priceText}</h3>
                    {addOnInLabel && (
                        <span>{priceLabel}</span>
                    )}
                    <BundleProviderLink bundle={bundle} />
                </div>
            </div>
        </div>
    )
}
