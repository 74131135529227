const nowYear = (new Date()).getYear();

export default class DataFilters {
    static yesNo(value) {
        return value ? 'Yes' : 'No';
    }
    static currency(value) {
        if (value === undefined) {
            return ''
        }
        return '$' + value
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }
    static noneIfBlank(value, uom) {
        return value ? `${value} ${uom}` : 'None';
    }
    static date(value) {
        const dateValue = parseDateFromString(value);
        const defaultFormat = `${getMonthFromDate(dateValue)} ${dateValue.getDate()}`;
        if (dateValue.getYear() === nowYear) {
            return defaultFormat;
        } else {
            return `${defaultFormat}, ${dateValue.getYear() + 1900}`
        }
    }
}

function getMonthFromDate(dateValue) {
    return dateValue.toLocaleString('default', { month: 'long' });
}

function parseDateFromString(value) {
    // if value is a string, convert to Date
    // assumption: incoming date is in ISO format (should be true for deserialized JSON)
    if (typeof value === 'string') {
        return new Date(value);
    }
    // if value is not a string, assume it is already a date, and return it as is
    return value;
}
