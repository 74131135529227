import config from './config';
import RequestService from './request';

export const REVIEW_TYPE_BUNDLE = 'bundle';

export default class ReviewService {
    static fetchReviews(reviewType, targetId, targetParentId) {
        const url = this.getUrl(reviewType, targetId, targetParentId);

        return RequestService.get(url)
            .then(json => {
                return json.data;
            });
    }
    static getUrl(reviewType, targetId, targetParentId) {
        switch (reviewType) {
            case 'bundle':
                return `${config.apiUrl}/providers/${targetParentId}/bundles/${targetId}/reviews`;

            default:
                throw new Error(`Invalid review type: ${reviewType}`);
        }
    }
    static async createReview(reviewType, targetId, targetParentId, reviewData) {
        const url = this.getUrl(reviewType, targetId, targetParentId);
        // TODO put all fetch calls in a common service
        const response = await RequestService.post(url, reviewData);
        return response.data;
    }

    static getRatingAggregateScore(reviews) {
        if (reviews.length === 0) {
            return 0;
        }
        const totalScore = reviews.reduce((sum, review) => sum + review.ratingScore, 0);
        return totalScore / reviews.length;
    }
}
