import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import config from '../../services/config';

function Footer() {
    const devMode = (process.env.NODE_ENV !== 'production');
    const nowYear = (new Date()).getYear() + 1900;

    return (
        <footer>
            <hr />
            <Row>
                <Col>
                    <span>&copy; 2015-{nowYear} Yukon Solutions</span>
                </Col>
                {config.beta && (
                <Col sm={{size: 'auto'}}>
                    <span className="font-italic">beta version</span>
                </Col>
                )}
                {devMode && (
                <Col sm>
                    <span className="font-italic">Development Mode</span>
                </Col>
                )}
                <Col className="text-right">
                    <Link to="/terms">Terms and Conditions</Link>
                </Col>
            </Row>
        </footer>
    )
}

export default withRouter(Footer);
