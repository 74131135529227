import axios from 'axios';

export default class RequestService {
    static async post(url, data) {
        return await axios.post(url, data);
    }
    static async get(url) {
        return await axios.get(url);
    }
}
