import { reduce, union, orderBy } from 'lodash';

export default class SearchResultService {
    static getBundleFromSearchResult(bundles, searchResult) {
        const bundleId = searchResult.bundleId;
        return this.getBundleById(bundles, bundleId);
    }
    static getBundleById(bundles, bundleId) {
        // TODO add error handling??  or let caller handle undefined??
        return bundles[bundleId];
    }

    static getAddOnNameText(searchResult) {
        const addOnNames = getAddOnNames(searchResult);
        const addOnLabel = addOnNames.length > 1 ? 'add-ons' : 'add-on';
        return ` (with ${addOnLabel} ${addOnNames.join(', ')})`;
    }

    static getAddOnPrice(searchResult, bundleMonthlyPrice) {
        let monthlyPrice = bundleMonthlyPrice;
        searchResult.searchScore.bestAddOns.forEach((addOnBundle) => {
            monthlyPrice += addOnBundle.monthlyPrice;
        });
        return monthlyPrice;
    }

    static getAddOnPriceLabel(searchResult) {
        const addOnLength = searchResult.searchScore.bestAddOns.length;
        if (addOnLength === 1) {
            return ' (with add-on)';
        } else if (addOnLength > 1) {
            return ' (with add-ons)';
        } else {
            return '';
        }
    }

// formerly
//    static getAddOnAndChannelText(searchResult) {
    static getChannelMatchText(searchResult) {
        const addOnText = getAddOnText(searchResult);
        const channelText = getChannelText(searchResult);
        if (addOnText && !channelText) {
            return addOnText;
        } else if (addOnText && channelText) {
            const totalLength = searchResult.channelMatches.length + getAllAddOnChannelMatches(searchResult).length;
            return `(${totalLength}) ${channelText} ${addOnText}`;
        } else if (!addOnText && searchResult.channelMatches.length) {
            return `(${searchResult.channelMatches.length}) ${channelText}`;
        } else {
            return 'None';
        }
    }

    static getChannelMatches(searchResult) {
        const channelMatches = searchResult.channelMatches;
        const addOnChannelMatches = getAllAddOnChannelMatches(searchResult);
        return [...channelMatches, ...addOnChannelMatches];
    }

    // returns the same number of search results as bundles passed in to it, minus add-ons
    static getSearchResults(selectedChannelIds, bundles, channels) {
        let searchResults = initSearchResults(bundles);
        if (selectedChannelIds.length > 0 && channels !== undefined) {
            const { channelMap, channelAddOnMap, addOnMap } = getChannelMaps(bundles, channels);
            selectedChannelIds.forEach(channelId => {
                const channel = channels.find(chan => chan.id === channelId);
                searchResults = addChannelToSearchResults(searchResults, channel, channelMap, channelAddOnMap, addOnMap);
            });
        }
        return sortSearchResults(searchResults);
    }

    static getSearchResult(selectedChannelIds, bundles, channels, bundleId) {
        const searchResults = this.getSearchResults(selectedChannelIds, bundles, channels);
        return findSearchResult(searchResults, bundleId);
    }
}

// internal functions

function getAddOnNames(searchResult) {
    return searchResult.searchScore.bestAddOns.map((addOnBundle) => {
        return addOnBundle.name;
    });
}

function getAddOnText(searchResult) {
    const channelMatches = getAllAddOnChannelMatches(searchResult);
    if (channelMatches.length) {
        const results = channelMatches.map((channelMatch) => {
            return channelMatch.name;
        });
        const addOnLabel = getAddOnNames(searchResult).length > 1 ? 'add-ons' : 'add-on';
        return ` (${results.length} from ${addOnLabel}: ${results.join(', ')})`;
    }
    return '';
}

function getAllAddOnChannelMatches(searchResult) {
    let channelMatches = [];
    searchResult.searchScore.bestAddOns.forEach((addOnBundle) => {
        const addOnChannelMatches = searchResult.addOnChannelMatches.find((addOnChannelMatch) => {
            return addOnChannelMatch.addOnBundle.id === addOnBundle.id;
        });
        channelMatches = channelMatches.concat(addOnChannelMatches.channelMatches);
    });
    return channelMatches;
}

function getChannelText(searchResult) {
    const value = searchResult.channelMatches;
    const result = value.map(match => match.name).join(', ');
    if (result) {
        return result;
    }
}

function getSearchScore(channelMatches, monthlyPrice) {
    const numberOfChannelMatches = channelMatches.length;
    if (numberOfChannelMatches === 0) {
        return getDefaultSearchScore(monthlyPrice);
    }
    const tieGoesToMoreChannels = (0.01 * numberOfChannelMatches);
    return (numberOfChannelMatches / monthlyPrice) + tieGoesToMoreChannels;
}

function getBestSearchScore(searchResult, bundleMonthlyPrice) {
    // default best score: bundle with no add-ons
    let bestScore = getSearchScore(searchResult.channelMatches, bundleMonthlyPrice);
    // add ons that will provide the best package
    let bestAddOns = [];

    if (searchResult.addOnChannelMatches.length > 0) {
        const addOnBundlePossibleCombinations = getAddOnCombinations(searchResult.addOnChannelMatches);
        addOnBundlePossibleCombinations.forEach((addOnCombination) => {
            if (addOnCombination.channelMatches.length > 0) {
                const addOnPrice = reduce(addOnCombination.addOnBundles, (sum, bundle) => {
                    return sum + bundle.monthlyPrice;
                }, 0);
                const monthlyPrice = bundleMonthlyPrice + addOnPrice;
                const channelMatches = searchResult.channelMatches.concat(addOnCombination.channelMatches);
                const addOnSearchScore = getSearchScore(channelMatches, monthlyPrice);
                if (addOnSearchScore > bestScore) {
                    bestScore = addOnSearchScore;
                    bestAddOns = addOnCombination.addOnBundles;
                }
            }
        });
    }

    return {
        bestScore,
        bestAddOns
    };
}

function getDefaultBestSearchScore(monthlyPrice) {
    return {
        bestScore: getDefaultSearchScore(monthlyPrice),
        bestAddOns: []
    };
}

function getDefaultSearchScore(monthlyPrice) {
    return -1 * monthlyPrice;
}

function getAddOnCombinations(addOnChannelMatches) {
    return getAddOnCombinationsInner({ addOnBundles: [], channelMatches: [] }, addOnChannelMatches, []);
}

// use recursion to get all combinations of add-on bundles that could be added to a bundle
// assumption: only add-on bundles with channel matches are passed to this function
// (i.e. the inputAddOnMatches array should not have any elements with channelMatches.length === 0)
//   this function will work fine in that scenario, but why create combinations that include add-ons with no channel matches?
function getAddOnCombinationsInner(
    existingCombination,
    inputAddOnMatches,
    accumulator) {
    for (let i = 0; i < inputAddOnMatches.length; i++) {
        const combination = addChannelMatchToCombination(existingCombination, inputAddOnMatches[i]);
        accumulator.push(combination);
        getAddOnCombinationsInner(combination, inputAddOnMatches.slice(i + 1), accumulator);
    }
    return accumulator;
}

function addChannelMatchToCombination(
    existingCombination,
    inputAddOnMatch) {
    return {
        addOnBundles: union(existingCombination.addOnBundles, [inputAddOnMatch.addOnBundle]),
        channelMatches: union(existingCombination.channelMatches, inputAddOnMatch.channelMatches)
    };
}

function getChannelMaps(bundles, channels) {
    const channelMap = {};
    const channelAddOnMap = {};
    const addOnMap = {};
    channels.forEach(channel => {
        channelMap[channel.id] = [];
        channelAddOnMap[channel.id] = [];
    });
    bundles.forEach(bundle => {
        if (bundle.addOn) {
            bundle.channels.forEach(channelId => {
                channelAddOnMap[channelId].push(bundle);
            });
        } else {
            bundle.channels.forEach(channelId => {
                channelMap[channelId].push(bundle);
            });
            bundle.addOnOptions.forEach(addOnOption => {
                if (!addOnMap[addOnOption]) {
                    addOnMap[addOnOption] = [];
                }
                const addOnMapItem = addOnMap[addOnOption];
                addOnMapItem.push(bundle);
            });
        }
    });
    return {
        channelMap,
        channelAddOnMap,
        addOnMap
    }
}

function findSearchResult(searchResults, bundleId) {
    return searchResults.find(searchResult => searchResult.bundleId === bundleId);
}

function addChannelToSearchResults(searchResults, channel, channelMap, channelAddOnMap, addOnMap) {
    const bundlesWithChannel = channelMap[channel.id];
    for (let i = 0; i < bundlesWithChannel.length; i++) {
        const bundle = bundlesWithChannel[i];
        const searchResult = findSearchResult(searchResults, bundle.id);
        searchResult.channelMatches.push(channel);
        searchResult.searchScore = getBestSearchScore(searchResult, bundle.monthlyPrice);
        // SearchResultService.updateSearchResult(searchResult);
    }
    // check all add-on bundles for channel matches
    const addOnBundlesWithChannel = channelAddOnMap[channel.id];
    for (let j = 0; j < addOnBundlesWithChannel.length; j++) {
        const bundle = addOnBundlesWithChannel[j];
        const bundlesWithAddOn = addOnMap[bundle.id];
        if (bundlesWithAddOn) {
            for (let k = 0; k < bundlesWithAddOn.length; k++) {
                const bundleWithAddOn = bundlesWithAddOn[k];
                const searchResult = findSearchResult(searchResults, bundleWithAddOn.id);
                addAddOnChannelMatch(searchResult, bundle, channel);
                searchResult.searchScore = getBestSearchScore(searchResult, bundleWithAddOn.monthlyPrice);
            }
        } else {
            console.warn(`add-on bundle '${bundle.id}' has this channel ('${channel.id}'), but the add-on is not part of any other bundle!`);
        }
    }
    return sortSearchResults(searchResults);
    // this.searchResults = this.searchResultService.updateSearchResults();
}

function addAddOnChannelMatch(searchResult, addOnBundle, channel) {
    const addOnChannelMatches = initAddOnChannelMatches(searchResult, addOnBundle);
    addOnChannelMatches.channelMatches.push(channel);
    return addOnChannelMatches
}

function initAddOnChannelMatches(searchResult, addOnBundle) {
    let addOnChannelMatches = searchResult.addOnChannelMatches.find(addOnChannelMatch  => {
        return addOnChannelMatch.addOnBundle.id === addOnBundle.id;
    });
    if (!addOnChannelMatches) {
        addOnChannelMatches = {
            addOnBundle: addOnBundle,
            channelMatches: []
        };
        searchResult.addOnChannelMatches.push(addOnChannelMatches);
    }
    return addOnChannelMatches;
}


function initSearchResults(bundles) {
    const searchResults = [];
    bundles.forEach(bundle => {
        if (!bundle.addOn) {
            searchResults.push({
                bundleId: bundle.id,
                channelMatches: [],
                addOnChannelMatches: [],
                searchScore: getDefaultBestSearchScore(bundle.monthlyPrice),
                showDetails: false
            });
        }
    });
    return searchResults;
}

function sortSearchResults(searchResults) {
    const newSearchResults = orderBy(searchResults, ['searchScore.bestScore'], ['desc']);
    return newSearchResults;
}

