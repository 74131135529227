import { SET_SEARCH_RESULT } from '../actions/search-result.action';

const initialState = '';

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_RESULT:
            return action.payload;

        default:
            return state;
    }
}
