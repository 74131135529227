import React from 'react';
import ReviewView from "./review-view";

export default function ReviewList({ reviews }) {
    return (
        <div className="saved-review-list row scroll-y">
            <React.Fragment>
                { !reviews.length && (
                    <div className="col text-center font-italic">No Reviews found</div>
                )}
                { !!reviews.length && reviews.map((review, index) => (
                    <div key={index} className="col-24 col-sm-12 col-xl-8">
                        <ReviewView review={review}/>
                    </div>
                ))}
            </React.Fragment>
        </div>
    )
}
