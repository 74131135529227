import React from 'react';
import DataFilters from "../../services/data-filters";

export default function BundleTechSpecs({ bundle }) {
    return (
        <React.Fragment>
            <h4>Technical Specifications</h4>
            <div className="provider-properties">
                <div className="borderless">
                    <div className="row">
                        <span
                            className="col"><label>Frames per second: </label><span> {bundle.videoSupport.fps}</span></span>
                    </div>
                    <div className="row">
                        <span
                            className="col"><label>Maximum Resolution: </label><span> {bundle.videoSupport.resolution}</span></span>
                    </div>
                </div>
                <div className="borderless">
                    <div className="row">
                        <span
                            className="col"><label>Surround Sound: </label><span> { DataFilters.yesNo(bundle.audioSupport.surroundSound) }</span></span>
                    </div>
                </div>
            </div>
            <div className="bundle-properties">
                <div className="row borderless">
                    <span className="col"><label>Maximum Streams: </label><span> {bundle.concurrentStreamMax}</span></span>
                </div>
                <div className="row borderless">
                    <span className="col"><label>User Profiles: </label><span> {bundle.userProfileMax}</span></span>
                </div>
            </div>
        </React.Fragment>
    )
}
