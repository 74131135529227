import React, { useState } from 'react';
import SearchResultService from '../../services/search-result';
import ProviderListSearch from './provider-list-search';
import ProviderListTable from './provider-list-table';

const updateSearchResults = function updateSelectedChannelsFunc(selectedChannels, setSearchResults, setSelectedChannelIds, bundles, channels) {
    const selectedChannelIds = selectedChannels.map(channel => channel.id);
    const searchResults = SearchResultService.getSearchResults(selectedChannelIds, Object.values(bundles), channels);
    setSearchResults(searchResults);
    setSelectedChannelIds(selectedChannelIds);
};

export default function ProviderListWithBundles({ bundles }) {
    const [searchResults, setSearchResults] = useState(SearchResultService.getSearchResults([], Object.values(bundles)));
    const [selectedChannelIds, setSelectedChannelIds] = useState([]);

    return (
        <React.Fragment>
            <ProviderListSearch
                updateSelectedChannels={(selectedChannels, channels) => updateSearchResults(selectedChannels, setSearchResults, setSelectedChannelIds, bundles, channels)}
                setSearchResults={setSearchResults}
            />
            <ProviderListTable
                searchResults={searchResults}
                bundles={bundles}
                selectedChannelIds={selectedChannelIds}
            />
        </React.Fragment>
    );
}
