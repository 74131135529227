import React, { useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

// TODO: implement login and logout
function logout() {
    console.warn('TODO: IMPLEMENT LOG OUT');
}

export default function Header() {
    const [collapsed, setCollapsed] = useState(true);
    const isAdmin = false;

    const linksForAll = (
        <NavItem>
            <NavLink tag={RRNavLink} exact to="/help" activeClassName="active">
                <span className="fas fa-question"></span> Help
            </NavLink>
        </NavItem>
    );

    const adminLinks = (
        <React.Fragment>
            <NavItem>
                <NavLink tag={RRNavLink} exact to="/channels" activeClassName="active">
                    <span className="fas fa-edit"></span> Channels
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={RRNavLink} exact to="/admin/providers" activeClassName="active">
                    <span className="fas fa-edit"></span> Providers
                </NavLink>
            </NavItem>
            {linksForAll}
            <NavItem>
                <NavLink tag={RRNavLink} exact to="#" onClick={() => logout()}>
                    <span className="fas fa-unlock-alt"></span> Logout
                </NavLink>
            </NavItem>
        </React.Fragment>
    );

    const nonAdminLinks = linksForAll;
    // Uncomment as part of implementing login/logout
    // const nonAdminLinks = (
    //     <React.Fragment>
    //         <NavItem>
    //             <NavLink href="/login" activeClassName="active">
    //                 <span className="fas fa-lock"></span> Admin
    //             </NavLink>
    //         </NavItem>
    //         {linksForAll}
    //     </React.Fragment>
    // );

    return (
        <Navbar color="primary" dark expand="md">
            <NavbarBrand href="/">
                <span className="fas fa-film"></span> Stream My Shows
            </NavbarBrand>
            <NavbarToggler onClick={() => setCollapsed(!collapsed)}/>

            <Collapse isOpen={!collapsed} navbar>
                <Nav navbar>
                    <NavItem>
                        <NavLink tag={RRNavLink} exact to="/providers" activeClassName="active">
                            <span className="fas fa-list"></span> Provider Guide
                        </NavLink>
                    </NavItem>
                </Nav>
                <Nav className="ml-auto" navbar>
                    {isAdmin && adminLinks}
                    {!isAdmin && nonAdminLinks}
                </Nav>
            </Collapse>
        </Navbar>
    )
}
