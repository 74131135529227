export default class ErrorsService {

    // used to handle errors from API responses
    // and normal JavaScript exceptions
    // returns the underlying error message
    static extractErrorMessage(err, failedAction = 'submitting form') {
        const defaultError = `Encountered an error when ${failedAction}`;
        // check if error is from a response
        if (!err || !err.response || !err.response.data) {
            // if it is NOT a response error, check if it has a message component
            // otherwise, return default error
            if (err && err.message) {
                return err.message;
            }
            return defaultError;
        }

        // error is a response
        if (err.response.status === 500) {
            return 'There was a problem on our end.  Please try again later.';
        }
        const errorData = err.response.data;
        if (errorData.error !== true) {
            return 'Error data is inconsistent.  Please review logs.';
        }
        if (errorData.message) {
            return errorData.message;
        }

        // no error message found; return default error
        return defaultError;
    }

    // used to return the structured error response from form submissions
    // returns an object
    //   key: field that has the error, e.g. "reviewerName"
    //   value: error message, e.g. "This field is required."
    //
    //   e.g. { "reviewerName": "This field is required", "ratingScore": "Should be a number between 1 and 5." }
    static extractFormErrors(err) {
        const defaultReturnValue = {};
        if (!err || !err.response || !err.response.data) {
            return defaultReturnValue;
        }
        const errorData = err.response.data;
        if (errorData.error && errorData.errors) {
            const errorObject = {};
            errorData.errors.forEach(error => {
                errorObject[error.field] = error.message;
            });
            return errorObject;
        }
        return defaultReturnValue;
    }

    // TODO: better job of transforming error for user consumption
    static log(error) {
        console.error(error);
    }
}
