import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'querystring';

import SearchResultService from '../../services/search-result';
import Loading from '../shared/loading';
import Error from '../shared/error';
import BundleReviews from '../bundles/bundle-reviews';
import BundleSummary from '../bundles/bundle-summary';
// import DeviceList from '../devices/device-list';
import { fetchBundles } from '../../redux/actions/bundle.action';
import {
    isBundleLoading, getBundles, isChannelLoading, getChannels, getBundleFetchError,
    isBundleFetchError, isChannelFetchError, getChannelFetchError
} from '../../redux/selectors';
import { fetchChannels } from '../../redux/actions/channel.action';
import ChannelList from "../channels/channel-list";
import BundleDvrDetails from "../bundles/bundle-dvr-details";
import BundleTechSpecs from "../bundles/bundle-tech-specs";
import ReviewService, {REVIEW_TYPE_BUNDLE} from "../../services/review";

function getChannelMatches(searchResult) {
    return SearchResultService.getChannelMatches(searchResult);
}

const channelQueryStringKey = "channels";

function BundleFullDetails(props) {
    const [bundle, setBundle] = useState(null);
    const [searchResult, setSearchResult] = useState(null);
    const [reviews, setReviews] = useState(null);

    // TODO cache review requests in redux store
    useEffect(() => {
        const fetchReviews = async () => {
            const foundResults = await ReviewService.fetchReviews(REVIEW_TYPE_BUNDLE, bundle.id, bundle.parentProvider.id);
            setReviews(foundResults);
        };

        if (bundle) fetchReviews();
    }, [bundle]);

    if (!bundle) {
        // TODO: this check to see if props.bundles has already been loaded (and if not, to load it)
        //       will have to be in every component that depends on bundle store
        if (!props.bundles) {
            if (!props.isBundleLoading && !props.isBundleFetchError) {
                props.fetchBundles();
            } else if(props.isBundleFetchError) {
                return <Error error={props.bundleFetchError} />
            }
        } else {
            const bundleId = props.match.params.id;
            const foundResult = SearchResultService.getBundleById(props.bundles, bundleId);
            if (!foundResult) {
                // TODO make a generic component for "something went wrong - check your URL"
                return <p>Bundle Not Found!
                    The end of the current URL seems to be incorrect: "{bundleId}".
                    Please double check the URL.
                </p>
            }
            setBundle(foundResult);
        }
        return <Loading />;
    }

    if (!searchResult) {
        // TODO: this check to see if props.channels has already been loaded (and if not, to load it)
        //       will have to be in every component that depends on channel store
        if (!props.channels) {
            if (!props.isChannelLoading && !props.isChannelFetchError) {
                props.fetchChannels();
            } else if(props.isChannelFetchError) {
                return <Error error={props.channelFetchError} />
            }
        } else {
            const search = props.location.search ? props.location.search.substr(1, props.location.search.length) : null;
            let selectedChannelIds = [];
            if (search) {
                const parsedQueryString = queryString.parse(search);
                if (parsedQueryString[channelQueryStringKey]) {
                    selectedChannelIds = parsedQueryString[channelQueryStringKey].split(',');
                }
            }
            setSearchResult(SearchResultService.getSearchResult(selectedChannelIds, Object.values(props.bundles), props.channels, bundle.id));
        }
        return <Loading />;
    }

    const channelMatches = getChannelMatches(searchResult);
    // TODO data model: add list of devices that support this bundle
    // const deviceMatches = [];

    return (
        <div className="bundle-details">
            <BundleSummary bundle={bundle} searchResult={searchResult} reviews={reviews}/>
            <div className="container">
                {/*<DeviceList devices={deviceMatches}/>*/}
                <ChannelList channels={bundle.channels} channelMatches={channelMatches}/>
                <BundleDvrDetails bundle={bundle} />
                <BundleTechSpecs bundle={bundle} />
                <BundleReviews bundle={bundle} reviews={reviews} setReviews={setReviews}/>
            </div>
        </div>
    )
}


// add imported selectors to props
const mapStateToProps = state => {
    return {
        bundles: getBundles(state),
        bundleFetchError: getBundleFetchError(state),
        isBundleLoading: isBundleLoading(state),
        isBundleFetchError: isBundleFetchError(state),
        channels: getChannels(state),
        channelFetchError: getChannelFetchError(state),
        isChannelLoading: isChannelLoading(state),
        isChannelFetchError: isChannelFetchError(state)
    };
};
// add imported user actions to props so they can be passed to auth function
const mapDispatchToProps = { fetchBundles, fetchChannels };

// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(BundleFullDetails);
