import React from 'react';

export default function BundleProviderLink({ bundle, hideLabelWhenSmall }) {
    const labelClassname = hideLabelWhenSmall ? 'd-none d-sm-inline' : '';
    return (
        <div className='col ml-3 bundle-provider-link'>
            <a href={bundle.url ? bundle.url : bundle.parentProvider.url} target="_blank" className="btn btn-sm btn-primary" rel="noopener noreferrer">
                <span className={labelClassname}>Visit &nbsp;</span><i className="fas fa-external-link-alt"></i>
            </a>
        </div>
    )
}
