import React, { useState } from 'react';
import { Button } from 'reactstrap';

import NewReviewForm from '../reviews/new-review-form';
import ReviewList from '../reviews/review-list';
import Loading from '../shared/loading';

function addNewReviewForm(setters) {
    setters.setShowReviewForm(true);
}

function onSave(savedData, reviews, setters) {
    setters.setShowReviewForm(false);
    setters.setUserReviewAdded(savedData);
    setters.setReviews([savedData, ...reviews]);
}

export default function BundleReviews({ bundle, reviews, setReviews }) {
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [userReviewAdded, setUserReviewAdded] = useState(null);

    if (!reviews) {
        return <Loading />;
    }

    const setters = {
        setReviews,
        setShowReviewForm,
        setUserReviewAdded
    };

    return (
        <div>
            <div className='row borderless'>
                <div className='col-auto mr-auto'>
                    <h4>Ratings & Reviews</h4>
                </div>
                <div className='col-auto'>
                    {!showReviewForm && !userReviewAdded && (
                        <Button color="link"
                                onClick={() => addNewReviewForm(setters)}
                        >Add a Review</Button>
                    )}
                </div>
            </div>
            {showReviewForm && (
                <NewReviewForm
                    showReviewForm={showReviewForm}
                    onCancel={() => setShowReviewForm(false)}
                    onSave={(savedData) => onSave(savedData, reviews, setters)}
                    reviewType='bundle'
                    reviewTargetId={bundle.id}
                    reviewTargetParentId={bundle.parentProvider.id}
                />
            )}
            {!showReviewForm && (
                <ReviewList reviews={reviews}/>
            )}
        </div>
    )
};
